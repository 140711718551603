import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon

const CourseForm = ({ onSyllabusGenerated, isGenerating }) => {
  const [topic, setTopic] = useState('');
  const [interests, setInterests] = useState('');

  const handleTopicChange = (e) => setTopic(e.target.value);
  const handleInterestsChange = (e) => setInterests(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSyllabusGenerated(topic, interests); // Trigger course generation
  };  

  return (
    <div className="container mx-auto p-6">
      <form onSubmit={handleSubmit} className="bg-gradient-to-br from-gray-800 to-gray-900 shadow-lg rounded-lg p-6">
        <div className="mb-4">
          <label htmlFor="topic" className="block text-sm font-semibold text-gray-300">Topic</label>
          <input
            type="text"
            id="topic"
            value={topic}
            onChange={handleTopicChange}
            className="mt-1 block w-full border-gray-500 rounded-md shadow-sm p-3 text-gray-900 focus:border-[#084494] focus:ring-[#084494] text-lg placeholder-gray-400"
            placeholder="Enter anything you're interested in learning"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="interests" className="block text-sm font-semibold text-gray-300">Interests</label>
          <input
            type="text"
            id="interests"
            value={interests}
            onChange={handleInterestsChange}
            className="mt-1 block w-full border-gray-500 rounded-md shadow-sm p-3 text-gray-900 focus:border-[#084494] focus:ring-[#084494] text-lg placeholder-gray-400"
            placeholder="Enter your interests (comma-separated)"
            required
          />
        </div>

        <button
          type="submit"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-[#084494] hover:bg-[#063a6c] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#084494]"
          disabled={isGenerating}  // Disable button while generating
        >
          {isGenerating ? (
            <div className="flex items-center">
              <FaSpinner className="animate-spin mr-2" /> {/* Spinner icon */}
              Generating
            </div>
          ) : (
            'Generate'
          )}
        </button>
      </form>
    </div>
  );
};

export default CourseForm;
