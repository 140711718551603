import React, { useState } from 'react';

const Profile = ({ profilePicture, onProfilePictureChange, onReturnToDashboard, userData, onSaveProfile }) => {
    const [editMode, setEditMode] = useState(false);
    const [updatedUserData, setUpdatedUserData] = useState({ ...userData });

    const handleEditToggle = () => {
        setEditMode(!editMode);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUserData({ ...updatedUserData, [name]: value });
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newProfilePicture = reader.result;
                setUpdatedUserData({ ...updatedUserData, profilePicture: newProfilePicture });
                onProfilePictureChange(newProfilePicture);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = () => {
        setEditMode(false);
        onSaveProfile(updatedUserData);  // Save the updated user data
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="max-w-md w-full p-6 bg-gray-800 rounded-lg shadow-md">
                <div className="text-center">
                    <img
                        src={updatedUserData.profilePicture || profilePicture}
                        alt="Profile"
                        className="w-24 h-24 rounded-full mx-auto mb-4"
                    />
                    {editMode && (
                        <div className="flex justify-center mb-4">
                            <input
                                type="file"
                                onChange={handleProfilePictureChange}
                                className="text-white"
                            />
                        </div>
                    )}
                    <h2 className="text-2xl font-semibold text-white">{updatedUserData.name}</h2>
                    <p className="text-white">{updatedUserData.bio}</p>
                    {editMode ? (
                        <div className="mt-4">
                            <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={updatedUserData.name}
                                onChange={handleInputChange}
                                className="w-full p-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <label className="block text-white text-sm font-bold mt-4 mb-2" htmlFor="bio">
                                Bio
                            </label>
                            <textarea
                                id="bio"
                                name="bio"
                                value={updatedUserData.bio}
                                onChange={handleInputChange}
                                className="w-full p-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    ) : (
                        <button
                            onClick={handleEditToggle}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Edit Profile
                        </button>
                    )}
                    {editMode && (
                        <div className="flex justify-center space-x-4 mt-4">
                            <button
                                onClick={handleSave}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Save Changes
                            </button>
                            <button
                                onClick={handleEditToggle}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            onClick={onReturnToDashboard}
                            className="bg-gray-600 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Return to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;