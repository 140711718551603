import React, { useState } from 'react';
import CourseContent from './components/CourseContent';
import CourseForm from './components/CourseForm';
import Profile from './components/Profile';
import axios from 'axios';
import './index.css';

const App = () => {
    const [syllabusGenerated, setSyllabusGenerated] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [activeSection, setActiveSection] = useState('dashboard');
    const [userData, setUserData] = useState({
        name: "John Doe",
        email: "johndoe@example.com",
        bio: "Passionate learner and software developer.",
        profilePicture: "https://via.placeholder.com/40",
    });

    const handleGenerateCourse = async (topic, interests) => {
        setIsGenerating(true);

        try {
            await axios.post('https://playdo-models.onrender.com/generate-course', { topic, interests });

            const checkIfContentReady = async () => {
                try {
                    const response = await axios.get('https://playdo-models.onrender.com/course-content-status');
                    if (response.data.status === 'ready') {
                        setSyllabusGenerated(true);
                        setIsGenerating(false);
                        setActiveSection('course');
                    } else {
                        setTimeout(checkIfContentReady, 2000);
                    }
                } catch (error) {
                    console.error('Error checking course content status:', error);
                    setIsGenerating(false);
                }
            };

            checkIfContentReady();
        } catch (error) {
            console.error('Error generating course:', error);
            setIsGenerating(false);
        }
    };

    const handleProfileClick = () => {
        setActiveSection('profile');
    };

    const handleProfilePictureChange = (newProfilePicture) => {
        setUserData({ ...userData, profilePicture: newProfilePicture });
    };

    const handleReturnToDashboard = () => {
        setActiveSection('dashboard');
    };

    const handleSaveProfile = (updatedUserData) => {
        setUserData(updatedUserData); // Update the user data state
        setActiveSection('dashboard'); // Optionally, navigate back to the dashboard after saving
    };

    const renderContent = () => {
        switch (activeSection) {
            case 'course':
                return syllabusGenerated ? (
                    <div className="flex-1 flex items-center justify-center p-6">
                        <CourseContent />
                    </div>
                ) : (
                    <div className="flex-1 flex items-center justify-center p-6">
                        <div className="text-center text-white text-2xl">
                            Generating Course...
                        </div>
                    </div>
                );
            case 'dashboard':
                return (
                    <div className="flex flex-col items-center justify-center flex-grow p-6">
                        <h1 className="text-5xl font-extrabold text-white mb-6">Playdo.ai</h1>
                        <CourseForm onSyllabusGenerated={handleGenerateCourse} isGenerating={isGenerating} />
                    </div>
                );
            case 'profile':
                return (
                    <Profile
                        profilePicture={userData.profilePicture}
                        onProfilePictureChange={handleProfilePictureChange}
                        onReturnToDashboard={handleReturnToDashboard}
                        userData={userData}
                        onSaveProfile={handleSaveProfile} // Pass the save handler to Profile
                    />
                );
            default:
                return (
                    <div className="flex items-center justify-center flex-grow p-6">
                        <div className="text-center text-white text-2xl">
                            Welcome to Playdo.ai! Select a section to get started.
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="flex min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 relative">
            <div className="flex-1 flex flex-col">
                {renderContent()}
            </div>
            <div
                onClick={handleProfileClick}
                className="absolute top-4 right-4 w-12 h-12 rounded-full bg-gray-800 border-2 border-white flex items-center justify-center cursor-pointer"
            >
                <img 
                    src={userData.profilePicture} 
                    alt="Profile" 
                    className="w-10 h-10 rounded-full"
                />
            </div>
        </div>
    );
};

export default App;
