import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CourseContent = () => {
    const [courseContent, setCourseContent] = useState(null);
    const [currentSection, setCurrentSection] = useState(0);
    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    useEffect(() => {
        const fetchCourseContent = async () => {
            try {
                const response = await axios.get('https://playdo-models.onrender.com/course-content');
                setCourseContent(response.data.sections);
            } catch (error) {
                console.error('Error fetching course content:', error);
            }
        };

        fetchCourseContent();
    }, []);

    if (!courseContent) {
        return <div>Loading course content...</div>; // or a loading spinner
    }

    const sectionTitles = Object.keys(courseContent);
    if (sectionTitles.length === 0) {
        return <div>No course content available.</div>;
    }

    const content = courseContent[sectionTitles[currentSection]];

    if (!content || content.length === 0) {
        return <div>No content available for this section.</div>;
    }

    const videoContent = content.filter(item => item.video).map(item => ({ type: 'video', content: item.video }));
    const quizContent = content.filter(item => item.quiz.length > 0).map(item => ({ type: 'quiz', content: item.quiz }));
    const projectContent = content.filter(item => Object.keys(item.project_ideas).length > 0).map(item => ({ type: 'project', content: item.project_ideas }));

    const filteredContent = [...videoContent, ...quizContent, ...projectContent];

    const handlePrevious = () => {
        if (currentContentIndex > 0) {
            setCurrentContentIndex(prev => prev - 1);
        } else if (currentSection > 0) {
            setCurrentSection(prev => prev - 1);
            setCurrentContentIndex(courseContent[sectionTitles[currentSection - 1]].length - 1);
        }
    };

    const handleNext = () => {
        if (currentContentIndex < filteredContent.length - 1) {
            setCurrentContentIndex(prev => prev + 1);
        } else if (currentSection < sectionTitles.length - 1) {
            setCurrentSection(prev => prev + 1);
            setCurrentContentIndex(0);
        }
    };

    const renderContent = (item) => {
        switch (item.type) {
            case 'video':
                return (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-3xl font-bold text-white mb-6">{sectionTitles[currentSection]}</h2>
                        <h3 className="text-xl font-semibold text-gray-300 mb-4">{content[0].subtopic}</h3>
                        <h4 className="text-lg font-medium text-gray-400 mb-2">Video</h4>
                        <div className="flex justify-center mb-4">
                            <iframe
                                className="w-full max-w-4xl h-56 sm:h-64 md:h-72 lg:h-80 xl:h-96"
                                src={`https://www.youtube.com/embed/${new URL(item.content.URL).searchParams.get('v')}`}
                                title={item.content.Title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <p className="text-gray-400 mt-2">Channel: {item.content.ChannelName}</p>
                    </div>
                );
            case 'quiz':
                return (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-3xl font-bold text-white mb-6">{sectionTitles[currentSection]}</h2>
                        <h3 className="text-xl font-semibold text-gray-300 mb-4">{content[0].subtopic}</h3>
                        <h4 className="text-lg font-medium text-gray-400 mb-2">Quiz</h4>
                        <form>
                            <ul className="list-disc list-inside text-gray-300">
                                {item.content.map((q, idx) => (
                                    <li key={idx} className="mb-4">
                                        <p className="font-semibold">{idx + 1}. {q.question}</p>
                                        <div className="ml-4">
                                            {q.options.map((option, optIdx) => (
                                                <div key={optIdx} className="mb-2">
                                                    <input
                                                        type="radio"
                                                        id={`q${idx}-o${optIdx}`}
                                                        name={`question${idx}`}
                                                        value={option}
                                                    />
                                                    <label htmlFor={`q${idx}-o${optIdx}`} className="ml-2">{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </form>
                    </div>
                );
            case 'project':
                return (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-3xl font-bold text-white mb-6">{sectionTitles[currentSection]}</h2>
                        <h3 className="text-xl font-semibold text-gray-300 mb-4">{content[0].subtopic}</h3>
                        <h4 className="text-lg font-medium text-gray-400 mb-2">Project Ideas</h4>
                        <ul className="list-disc list-inside">
                            {Object.values(item.content).flat().map((idea, idx) => (
                                <li key={idx} className="text-gray-300">{idea}</li>
                            ))}
                        </ul>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="space-y-8 p-6 max-w-4xl mx-auto">
            {/* Content Box */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                {filteredContent.length > 0 && renderContent(filteredContent[currentContentIndex])}

                {/* Navigation Buttons */}
                <div className="flex justify-between mt-4">
                    <button
                        className="bg-[#084494] text-white py-1 px-4 rounded"
                        onClick={handlePrevious}
                        disabled={currentSection === 0 && currentContentIndex === 0}
                    >
                        Previous
                    </button>
                    <button
                        className="bg-[#084494] text-white py-1 px-4 rounded"
                        onClick={handleNext}
                        disabled={currentSection === sectionTitles.length - 1 && currentContentIndex >= filteredContent.length - 1}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CourseContent;
