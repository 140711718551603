import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import App from './App';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import ProtectedRoute from './components/ProtectedRoute';

const RootComponent = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleUserLogin = (userProfile) => {
    console.log('User profile received in handleUserLogin:', userProfile);

    setUser(userProfile);
    console.log('User state updated:', userProfile);

    // Redirect to the home page after login
    navigate('/');
    console.log('Navigated to home page');
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLogin={handleUserLogin} />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute user={user}>
            <App />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <RootComponent />
    </Router>
  </React.StrictMode>
);